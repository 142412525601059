<template>
  <div class="admin-container">
    <v-row class="justify-space-between">
      <v-col cols="12" sm="6" md="4">
        <h1 class="col-secondary-1">Vizualizace</h1>
      </v-col>
      <v-col
        align-self="center"
        v-if="user.role != 'END_USER' && user.role != 'COMPANY_CUSTOMER'"
      >
        <v-row class="fix-visualization-row">
          <!-- <v-col align-self="center" md="2" class="text-right"> -->
          <!-- class="pa-0 flex-md-0 mt-4 mt-md-0" -->
          <v-btn
            x-small
            tile
            color="secondary"
            height="32"
            class="mobile-width-send-message"
            @click="$router.push({ name: 'Visualization-new' })"
            ><v-icon small color="primary">mdi-plus</v-icon> Nová
            vizualizace</v-btn
          >
          <!-- </v-col> -->
        </v-row>
      </v-col>
    </v-row>
    <div v-for="(t, tidx) in data" :key="t.id" class="mt-8">
      <span>{{ t.name }}</span>
      <span class="d-block col-secondary-3"
        ><span class="d-inline-block mr-2">{{ t.count_text }}</span>
        {{ t.created_at }}</span
      >
      <div class="photo-gallery-wrapper">
        <div
          v-for="(image, imageIdx) in t.documents"
          :key="image.id"
          class="cursor-pointer"
        >
          <img
            v-if="imageIdx < 5"
            class="photo-gallery-single-item"
            :src="image.path"
            :alt="image.name"
            @click="downloadDoc(image.documentId)"
          />
        </div>
        <button
          class="show-all-images"
          @click="
            $router.push({
              name: 'Visualization-detail',
              params: { id: t.id },
            })
          "
        >
          <div>Zobrazit vše</div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import authHandler from "../api/authHandler";
import permissionDeniedHandler from "../api/permissionDeniedHandler";
import bus from "../plugins/bus";
import errorHandler from "../api/errorHandler";
import visualizationService from "../api/visualizationService";
import documentService from "../api/documentService";

export default {
  data() {
    return {
      data: null,
    };
  },
  async created() {
    try {
      //   await this.$store.dispatch("getCurrentUser");
      if (this.getSelectedBuilding() != null) {
        await this.index();
      } else {
        this.$router.push({ name: "Dashboard" });
        bus.$emit("snackbar", {
          text: "Není vybrána žádná stavba.",
          color: "error",
        });
      }
    } catch (error) {
      if (!authHandler(error)) this.$router.push({ name: "Login" });
      permissionDeniedHandler(error, this.$router);
    }
  },
  methods: {
    getSelectedBuilding() {
      const building = JSON.parse(localStorage.getItem("building")) || null;
      return building;
    },
    async downloadDoc(id) {
      try {
        bus.$emit("processing");
        await documentService.show(id);
        bus.$emit("processing", false);
      } catch (error) {
        bus.$emit("processing", false);
        errorHandler(error);
      }
    },
    async index() {
      try {
        bus.$emit("processing");
        const data = await visualizationService.index();
        this.data = data;
        console.log(data);
        bus.$emit("processing", false);
        await bus.$emit("refresh_sections");
      } catch (error) {
        console.error(error);
        bus.$emit("processing", false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error.message
        ) {
          bus.$emit("snackbar", {
            text: error.response.data.error.message,
            color: "error",
          });
        }
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    stahnout() {
      return {
        backgroundImage: `url(${require("@/assets/stahnout.svg")} !important`,
      };
    },
  },
};
</script>

<style></style>
